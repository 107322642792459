<template>
  <b-card>
    <page-header
      name="Templates de Email"
    >

    </page-header>

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getTemplates"
    >

      <template
        #cell(action)="data"
      >
        <i2-icon-button
          icon="EditIcon"
          variant="outline-primary"
          @click="setCurrent(data.item)"
        />

      </template>
    </Grid>
  </b-card>
</template>

<script>
import Grid from '@/components/i2/grid/Grid.vue'
import userType from '@/variables/userTypes'

export default {
  components: {

    Grid,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-10',
        },
        {
          key: 'subject',
          label: 'Assunto',
          class: 'w-40',
        },
        {
          key: 'description',
          label: 'Descrição',
          class: 'w-60',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10 text-center',
        },
      ],
      items: [],
      current: {},
      isActivated: false,
      isUploadActivated: false,
      isRegisterPrizes: false,
      userType,
    }
  },

  mounted() {
    this.getTemplates()
  },
  methods: {
    async getTemplates() {
      this.items = await this.$http.get('email-templates')
    },
    setCurrent(item) {
      this.$router.push(`/mail-template/${item.uuid}`)
    },
    setUploadComments(promotion) {
      this.current = { ...promotion }
      this.isUploadActivated = true
    },
    newPrizeDraw() {
      this.current = {}
      this.isActivated = true
    },
    onSave() {
      this.current = {}
      this.isActivated = false
      this.isUploadActivated = false
      this.isRegisterPrizes = false
      this.getPrizeDraws()
    },
    onCancelEdit() {
      this.isActivated = false
      this.isUploadActivated = false
      this.isRegisterPrizes = false
    },
    setPrizeDrawRegisterPrizes(prizeDraw) {
      this.isRegisterPrizes = true
      this.current = { ...prizeDraw }
    },
    closeRegisterPrizes() {
      this.isRegisterPrizes = false
      this.current = {}
    },
  },
}
</script>
<style>

</style>
